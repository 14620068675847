import { useTranslation } from "react-i18next";
import { FiAlertOctagon } from "react-icons/fi";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";

import { Button } from "./ui/Button";

interface ReportProblemButtonComponentProp{
    title?:string,
    isLoading:boolean;
    onClick?: () => void;
}

export function ReportProblemButtonComponent(prop:ReportProblemButtonComponentProp) {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-col mobile:static items-center tablet:items-start justify-center mobile:w-1/2">
            <Button disabled={prop.isLoading} onClick={prop.onClick} type="submit">{prop.title ?? t("app.shared.button.send")} </Button>
           
            <Link to={appRoutesObj.getIssuePagePath()}>
              <div className="flex flex-row gap-2 pt-2 text-gray-400">
                <FiAlertOctagon className="w-4 h-4" />
                <p className={` text-gray-400 ${i18n.language === 'en' ? "text-sm font-helveticaMedium" : "text-sm font-arMyriadBold"}`}>
                  {t("app.shared.button.problem")}
                </p>
              </div>
            </Link>
          </div>
  );
}
