import { useState } from "react";
import CountUp from "react-countup";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import TitleComponent from "src/components/common/title-component";
import { Button } from "src/components/common/ui/Button";
import Tabs from "src/components/common/ui/Taps";
import CardComponent from "src/components/user/V2/card-component";
import { ColorsEnum, VariantsEnum, SizesEnum } from "src/core/enums/ui.enums";
import { opengraphMetaTags } from "src/core/helpers/example.helper";
import {
  getDisruptInNumbersData,
  SelectionCriteriaDisruptData,
} from "src/core/statics/disrupt.static";

import DisruptTimer from "./disrupt-timer";

function DisruptContainer() {
  const { t, i18n } = useTranslation();
  const [subPage, setSubPage] = useState<number>(0);
  const title = [
    "containers.InternalProgramsContainer.aboutProgram",
    "containers.InternalProgramsContainer.programDetails",
  ];
  const firstTap = subPage === 0;
  const secondTap = subPage === 1;

  return (
    <>
      <Helmet
        title={`${t("app.shared.theGarage")} | ${t(
          "container.disruptContainer.ditruptOverView"
        )}`}
        meta={[
          ...opengraphMetaTags({
            title: `${t("app.shared.theGarage")} | ${t(
              "container.disruptContainer.ditruptOverView"
            )}`,
            description: `${t(
              "component.user.SidebarComponent.theGarageDescreption"
            )}`,
            logo: "/assets/images/logo.png",
          }),
          {
            name: "description",
            content: `${t(
              "component.user.SidebarComponent.theGarageDescreption"
            )}`,
          },
        ]}
      />
      <div className="flex flex-col gap-6 px-2">
        <img
          src="/assets/images/disrupt.svg"
          alt="distrupt"
          className="w-[12%] tablet:w-[20%] tablet:mx-auto mobile:w-[30%] mobile:mx-auto flex justify-center items-center"
        />
        <div className="flex">
          {title.map((val, index) => (
            <div
              key={index}
              className={`flex rounded-lg ${
                subPage === index
                  ? i18n.language === "ar"
                    ? "bg-gradient-to-l from-secondary/20"
                    : "bg-gradient-to-r from-secondary/20"
                  : " text-neutral-400 "
              }`}
            >
              <Tabs
                title={val}
                onSelect={() => {
                  setSubPage(index);
                }}
              />
              {index === 0 && <div className=" w-0.5 bg-neutral/10 mx-2"></div>}
            </div>
          ))}
        </div>

        {firstTap && (
          <div className="mt-2 mobile:h-screen">
            <div className="flex justify-between gap-8 mobile:flex-col tablet:flex-col">
              <div className="flex flex-col w-[60%] mobile:w-full tablet:w-full tablet:items-center gap-4">
                <div className="flex flex-col mobile:items-center mobile:justify-center tablet:items-center tablet:justify-center mx-auto gap-4">
                  <p
                    className={`${
                      i18n.language === "en"
                      ? " font-helveticaMedium text-lg"
                      : "font-arMyriad text-xl"
                    } text-neutral-400 text-justify h-auto w-full px-2`}
                  >
                    {t("container.disruptContainer.ditruptOverView")}
                  </p>
                  <Link to={appRoutesObj.getDistruptFormPath()}>
                    <Button
                      type="submit"
                      color={ColorsEnum.Primary}
                      variant={VariantsEnum.Filled}
                      size={SizesEnum.Small}
                      className={` ${
                        i18n.language === "en"
                          ? "text-sm"
                          : "font-arMyriadBold text-base"
                      }`}
                    >
                      {t("app.shared.button")}
                    </Button>
                  </Link>
                </div>
              </div>
              <div className="w-[45%] largeLaptop:w-[40%] tablet:w-full mobile:w-full mobile:pt-7">
                <TitleComponent
                  color="text-white"
                  title="container.disruptContainer.DisruptInNumbers"
                 
                />
                <div className="grid grid-cols-2 pt-4 gap-4 w-full h-auto">
                  {getDisruptInNumbersData.map((val, index) => (
                    <div className="py-0 bg-black/20 rounded-2xl" key={index}>
                      <p className="text-2xl font-helveticaBold text-center text-secondary">
                        +
                        <CountUp
                          className="text-[23px] font-helveticaBold text-center text-secondary"
                          end={
                            typeof val.Number === "string"
                              ? parseFloat(val.Number)
                              : val.Number
                          }
                          duration={2}
                          useGrouping={false}
                        />
                        {val.char}
                      </p>
                      <p
                        className={`${
                          i18n.language === "ar"
                            ? "font-arMyriad text-base leading-5 laptop:text-sm"
                            : "font-helveticaMedium text-sm laptop:text-xs"
                        } py-1 pt-2 text-center text-neutral-400`}
                      >
                        {t(`${val.label}`)}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="pt-10">
              <DisruptTimer />
            </div>
          </div>
        )}
        {secondTap && (
          <div className="flex flex-col gap-y-6 mx-2 mobile:h-screen ">
            <div>
              <TitleComponent
                color="text-white"
                title="container.disruptContainer.theMarket"
             
              />
              <p
                className={`${
                  i18n.language === "en"
                    ? " font-helveticaMedium text-lg"
                    : "font-arMyriad text-xl"
                } text-neutral-400`}
              >
                {t("container.disruptContainer.disruptMarket")}
              </p>
            </div>
            <div>
              <TitleComponent
                color="text-white"
                title="app.shared.selectionCriteria"
             
              />
              <div className="grid grid-cols-3 w-fit gap-4 tablet:grid-cols-2 tablet:px-4 mobile:grid-cols-1 mobile:px-4">
                {SelectionCriteriaDisruptData.map((val, index) => (
                  <div key={index}>
                    <CardComponent
                      margin={
                        index > 2 ? "hover:-mt-20 mobile:hover:-mt-0" : ""
                      }
                      widthHover="hover:w-[19%] desktop:hover:w-[21%] largeLaptop:hover:w-[20%] laptop:hover:w-[19%]"
                      icon={<val.icon />}
                      title={val.title}
                      description={val.description}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DisruptContainer;
