import { useTranslation } from "react-i18next";
import useModeStore from "src/core/helpers/useModeStore";

export interface ICardComponentProps {
  title?: string;
  icon?: JSX.Element | undefined;
  margin?: string;
  description?: string;
  widthHover?: string;
}

function CardComponent({
  title,
  icon,
  description,
  margin,
  widthHover,
}: ICardComponentProps) {
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();
  const isDarkMode = mode === "dark";

  return (
    <div
      className={`flex items-center justify-center h-[50px] w-full px-4 bg-black/20 rounded-2xl relative group hover:w-[31.5%] hover:h-[128px] hover:z-50 hover:absolute hover:tablet:w-full hover:tablet:relative  hover:mobile:w-full hover:mobile:relative hover:flex-col ${margin} ${widthHover} ${
        isDarkMode ? "hover:bg-[#201F1E]" : "hover:bg-[#47555F]"
      }  `}
    >
      <div
        className={`flex absolute group-hover:top-3 items-center justify-center w-fit gap-2 ${
          i18n.language === "en" ? "left-0 px-4 " : "right-4"
        }`}
      >
        <div className="text-secondary">{icon}</div>
        <p
          className={`flex items-start justify-start text-justify w-fit text-white ${
            i18n.language === "en"
              ? "font-helveticaMedium text-sm"
              : "font-arMyriad text-lg pl-3"
          }`}
        >
          {t(`${title}`)}
        </p>
      </div>
      <p
        className={`text-neutral-400 pt-4 text-justify opacity-0 group-hover:flex-col group-hover:opacity-100 ${
          i18n.language === "en"
            ? "font-helveticaMedium text-sm"
            : "font-arMyriad text-lg"
        }`}
      >
        {t(`${description}`)}
      </p>
    </div>
  );
}

export default CardComponent;
