function MediaSkeletonCard(): JSX.Element {
  return (
    <div
      className={
        "flex h-[155px] w-full animate-pulse items-center justify-center rounded-2xl bg-neutral-800 laptop:h-24 largeLaptop:h-[120px]"
      }
    />
  );
}

export default MediaSkeletonCard;
