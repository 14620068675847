import { onlyNumbers_En } from "src/components/common/ui/regex";
import * as Yup from "yup";
export const validationSchema = () =>
    Yup.object().shape({
        name: Yup.string().required('form.validation.required'),
        email: Yup.string().required('form.validation.required').email('form.validation.email'),
        mobile:Yup.string()
        .trim()
        .required("form.validation.mobile.required")
        .matches(onlyNumbers_En, "form.validation.mobile.no.numbers")
        .min(9, "form.validation.mobile.min.9")
        .max(15, "form.validation.mobile.max.15"),
        vehicle: Yup.string().required('form.validation.required'),
        nationality: Yup.string().required('form.validation.required')
            .max(255, "form.validation.nationlity.max.255"),
        equipment: Yup.string().required('form.validation.required'),
        quantity: Yup.number().required('form.validation.required'),
        description: Yup.string().required('form.validation.required'),
        notes: Yup.string(),
        permit_date: Yup.string().required('form.validation.required'),
        reason: Yup.string().required('form.validation.required').max(250,"form.validation.reason.max.250"),
        transfer_from: Yup.string().required('form.validation.required'),
        transfer_to: Yup.string().required('form.validation.required'),
        refer_name: Yup.string().required('form.validation.required'),
        refer_mobile: Yup.string()
        .trim()
        .required("form.validation.mobile.required")
        .matches(onlyNumbers_En, "form.validation.mobile.no.numbers")
        .min(9, "form.validation.mobile.min.9")
        .max(15, "form.validation.mobile.max.15"),
    });
