/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import MediaSkeletonContainer from "src/containers/user/skeleton/media-skeleton-container";
import { GalleryEnum } from "src/core/enums/example.enum";
import { handleRequestError } from "src/core/helpers/error.helper";
import { opengraphMetaTags } from "src/core/helpers/example.helper";
import { GalleryModel } from "src/core/types/user.type";
import { getGalleryService } from "src/services/gallery-service";

import Pagination from "../common/Pagination/pagination";
import PopupTapsComponent from "../common/pop-up-taps-components";

function MenueImgComponent() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const paginationLimit = 12;
  const [openTab, setOpenTab] = useState<string>(
    "component.user.MenueImgComponent.all"
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imgParams, setImgParams] = useState<string>(GalleryEnum.ALL);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [data, setData] = useState<GalleryModel>();
  const [page, setPage] = useState<number>(1);
  const [index, setIndex] = useState<number>(0);

  const galleryItems = [
    { tapName: "component.user.MenueImgComponent.all" },
    { tapName: "component.user.MenueImgComponent.plus" },
    { tapName: "app.shared.incubator" },
    { tapName: "app.shared.disrupt" },
  ];

  const getGalleryData = useCallback(async () => {
    setIsLoading(true);
    try {
      const dataGallery = await getGalleryService(
        imgParams.toLowerCase(),
        paginationLimit,
        (page - 1) * paginationLimit
      );

      setData(dataGallery);
      setIsLoading(false);
    } catch (error) {
      handleRequestError(error);
    }
  }, [imgParams, page]);
  useEffect(() => {
    getGalleryData();
  }, [getGalleryData]);

  return (
    <>
      <Helmet
        title={`${t("app.shared.theGarage")} | ${t(
          "container.user.community.communitymedia"
        )}`}
        meta={[
          ...opengraphMetaTags({
            title: `${t("app.shared.theGarage")} | ${t(
              "container.user.community.communitymedia"
            )}`,
            description: `${t(
              "component.user.SidebarComponent.theGarageDescreption"
            )}`,
            logo: "/assets/images/logo.png",
          }),
          {
            name: "description",
            content: `${t(
              "component.user.SidebarComponent.theGarageDescreption"
            )}`,
          },
        ]}
      />
      <PopupTapsComponent
        icon={
          <IoMdClose
            size={32}
            className="text-white"
            onClick={() => navigate(-1)}
          />
        }
        index={index}
        taps={
          <div className="flex mobile:gap-0 mobile:pt-4">
            {galleryItems.map((val, index) => (
              <div
                onClick={() => {
                  setIndex(index);
                  setOpenTab(val.tapName);
                  switch (val.tapName) {
                    case "component.user.MenueImgComponent.plus":
                      setImgParams(GalleryEnum.PLUS);
                      break;
                    case "app.shared.disrupt":
                      setImgParams(GalleryEnum.DISRUPT);
                      break;
                    case "app.shared.incubator":
                      setImgParams(GalleryEnum.INCUBATOR);
                      break;

                    default:
                      return setImgParams(GalleryEnum.ALL);
                  }
                  setFirstRender(false);
                }}
                key={index}
              >
                <div
                  key={index}
                  className={`${
                    openTab === val.tapName ||
                    (firstRender && val.tapName === GalleryEnum.ALL)
                      ? "rounded-t-2xl bg-gray-400/5 text-primary backdrop-blur-lg backdrop-filter mobile:bg-gradient-to-b mobile:from-[rgba(120,113,108,0.1)] mobile:to-[rgba(120,113,108,0.1)] mobile:backdrop-filter-none"
                      : "text-white"
                  } text-center text-base ${
                    i18n.language === "en"
                      ? "font-helveticaMedium"
                      : "font-arMyriad"
                  } h-10 w-[162px] cursor-pointer items-center py-1 mobile:w-[75px]`}
                >
                  {val.tapName === GalleryEnum.ALL
                    ? "All"
                    : `${t(val.tapName)}`}
                </div>
              </div>
            ))}
          </div>
        }
      >
        <div>
          <div>
            {isLoading ? (
              <MediaSkeletonContainer count={12} />
            ) : (
              <div className="grid grid-cols-4 grid-rows-3 gap-4 px-2 pt-2 text-white mobile:grid-cols-2 mobile:pb-10 tablet:ml-8 tablet:grid-cols-2 largeLaptop:gap-2">
                {data?.result_list.map((val, index) => (
                  <div key={index}>
                    <img
                      className="h-[155px] w-full rounded-2xl laptop:h-24 largeLaptop:h-[120px]"
                      src={val.image_url}
                      alt="image media"
                      loading="lazy"
                    />
                  </div>
                ))}
              </div>
            )}
            <div className="flex justify-start px-3 mobile:justify-center tablet:justify-center">
              <Pagination
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                totalPage={Math.ceil(data?.total_count! / paginationLimit)}
                page={page}
                limit={paginationLimit}
                onPageChange={setPage}
                totalCount={data?.total_count || 0}
              />
            </div>
          </div>
        </div>
      </PopupTapsComponent>
    </>
  );
}

export default MenueImgComponent;
