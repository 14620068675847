import { IoMdClose } from "react-icons/io";
import useModeStore from "src/core/helpers/useModeStore";

export interface Props {
  video: string;
  onClose: () => void;
}

function PopupVideo(props: Props) {
  const { mode } = useModeStore();
  const handleClose = () => {
    props.onClose();
  };

  return (
    <div className="fixed z-50 top-0 left-0 right-0 bottom-0 w-full h-max ">
      <div
        className={`flex flex-col m-auto mt-[15%] min-h-[241px] max-w-[621px] mobile:w-[80%] gap-6  p-4 rounded-2xl ${
          mode === "dark"
            ? "bg-neutral-800 border-neutral/50"
            : "bg-neutral-300 border-white/50"
        }  backdrop-blur-xl border`}
      >
        <div className="flex items-end justify-end">
          <IoMdClose
            size={24}
            color={`${mode === "dark" ? "white" : ""}`}
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>
        <div className="flex justify-center">
          <video controls src={props.video} className="w-full rounded-2xl" />
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default PopupVideo;
