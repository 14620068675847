import { useTranslation } from "react-i18next";
import { FiCheck } from "react-icons/fi";
import CardComponent from "src/components/user/card-component";

interface NumberStepsProps {
  activeIndex: number;
  index: number;
  color: string;
  completed?: boolean;
  label: string;
  completedClass?: string;
  className?: string;
  size?: string;
  onClick?: () => void;
}
export default function NumberStepsComponent({
  activeIndex,
  index,
  completed = false,
  color,
  label,
  className,
  completedClass,
}: NumberStepsProps) {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-col gap-y-3 justify-center items-center mx-auto pb-6">
      <div className="flex items-center justify-center">
        <CardComponent className="w-[30px] h-[30px] gradient-card-border bg-neutral/10">
          {completed ? (
            <FiCheck
              size={24}
              className={`text-primary px-1 pt-1.5 text-center`}
            />
          ) : (
            <a
              className={`text-center text-base  px-2.5 ${
                i18n.language === "en"
                  ? "font-helveticaMedium"
                  : "font-arMyriad"
              } ${
                activeIndex === index && completed === false
                  ? "text-primary "
                  : "text-white"
              }`}
            >
              {index + 1}
            </a>
          )}
        </CardComponent>
      </div>
      <p
        className={`w-full leading-tight whitespace-nowrap ${className} ${
          activeIndex === index && completed === false
            ? "text-primary"
            : `text-${color}`
        }
            ${completed ? `text-white ${completedClass}` : ""}
          ${
            i18n.language === "en"
              ? "font-helveticaMedium text-base mobile:text-[10px]"
              : "font-arMyriad font-normal text-secondary text-lg mobile:text-[17px]"
          }
          `}
      >
        {`${t(label)}`}
      </p>
    </div>
  );
}
