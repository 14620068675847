import React from "react";
import { useTranslation } from "react-i18next";

interface IbackButtonProps {
  onClick: () => void;
  isLoading:boolean;
}

export default function BackButton({isLoading, onClick }: IbackButtonProps) {
  const { t, i18n } = useTranslation();

  return (
    <button
    disabled={isLoading}
      className={`h-10 mobile:px-0 mobile:w-1/2 text-sm leading-4 border rounded-lg 
      ${
        i18n.language === "ar"
          ? "px-14 font-arMyriadBold "
          : "px-12 font-helveticaBold"
      } mobile:px-0 py-0 text-base 
      bg-neutral/10 border-primary backdrop-blur-lg text-primary hover:bg-primary-700`}
      onClick={onClick}
    >
      {t("app.shared.back")}
    </button>
  );
}
