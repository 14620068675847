import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import SharedSectionComponent from "src/components/shard-Section";
import { NewsTypesEnum } from "src/core/enums/service.enums";
import { handleRequestError } from "src/core/helpers/error.helper";
import useModeStore from "src/core/helpers/useModeStore";
import { GarageNewsList } from "src/core/types/user.type";
import { getNewsListService } from "src/services/news.service";

import NewsSectionSkeletonCard from "../skeleton/news-section-skeleton-card";

function NewsSectionComponent() {
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();
  const isDarkMode = mode === "dark";
  const [news, setNews] = useState<GarageNewsList>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getAllGarageNews = useCallback(async () => {
    setIsLoading(true);
    try {
      const newsLater = await getNewsListService(
        i18n.language.toUpperCase(),
        NewsTypesEnum.NEWS_LETTER,
        0,
        1
      );
      setIsLoading(false);
      setNews(newsLater);
    } catch (error) {
      handleRequestError(error);
    }
  }, [i18n.language, 1]);

  useEffect(() => {
    getAllGarageNews();
  }, [getAllGarageNews]);

  return (
    <SharedSectionComponent
      component={
        <div>
          {isLoading ? (
            <NewsSectionSkeletonCard />
          ) : (
            <div className="relative flex flex-col items-start justify-center h-fit mobile:overflow-hidden tablet:overflow-hidden">
              <img
                className={`h-48 ${i18n.language ==='en'? "laptop:h-36":"laptop:h-32"} w-full mobile:h-[234px] object-cover rounded-lg`}
                src={news?.result_list[0]?.url || ""}
                alt="news img"
                loading="eager"
              />
              <div className="bg-black/50 w-[100%] rounded-b-lg  h-1/3 bottom-0 absolute  px-1 justify-center flex flex-col">
                <p
                  className={`text-white px-1 w-full h-9 ${
                    i18n.language === "en"
                      ? "text-xs font-helveticaMedium"
                      : "text-lg font-arMyriad font-normal"
                  }`}
                >
                  {news?.result_list[0]?.title}
                </p>
                <Link
                  className="w-full"
                  to={appRoutesObj.getNewsDetailsPageUrl(
                    news?.result_list[0]?.id || ""
                  )}
                >
                  <p
                    className={`text-sm laptop:text-xs text-primary underline text-end ${
                      isDarkMode
                        ? "hover:text-primary-600"
                        : "hover:text-primary-50"
                    } ${
                      i18n.language === "ar"
                        ? " text-left font-arMyriad "
                        : "text-right font-helveticaMedium font-light"
                    }`}
                  >
                    {t("component.user.SidebarComponent.findMore")}
                  </p>
                </Link>
              </div>
            </div>
          )}
        </div>
      }
      title={"component.user.SidebarComponent.ourNewsTitle"}
    />
  );
}
export default NewsSectionComponent;
