import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import useModeStore from "src/core/helpers/useModeStore";
import { ActivitiesElement } from "src/core/statics/booking.static";
import { disruptStatus } from "src/services/disrupt-service";

import CardComponent from "./card-component";
import DisruptTimeAndDateComponent from "./disrupt-time-and-date-component";

export default function ActivitiesComponent() {
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const distruptStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await disruptStatus();
      setIsOpen(result.is_open);
      setIsLoading(false);
    } catch (error) {
      error;
    }
  }, []);
  useEffect(() => {
    distruptStatus();
  }, [distruptStatus]);
  const isDarkMode = mode === "dark";

  return (
    <>
      <div className="flex flex-row items-center h-full p-2 mobile:p-0 tablet:p-0">
        <div className="mobile:hidden tablet:hidden">
          {ActivitiesElement.map((activity, index) => (
            <div key={index}>
              <Link to={appRoutesObj.getDisruptPagePath()}>
                <CardComponent
                  isHover={true}
                  className={`text-white flex-wrap w-[415px] laptop:w-[390px] h-[120px] largeLaptop:h-[100px] rounded-2xl py-3 px-4 laptop:py-1.5 laptop:px-1.5 laptop:h-[100px] from-neutral-900/10 via-neutral-900/10 to-secondary/10 ${
                    i18n.language === "en"
                      ? "bg-gradient-to-br"
                      : "bg-gradient-to-bl"
                  } ${
                    isDarkMode
                      ? "border border-gray-600/50"
                      : "border border-neutral/50"
                  }`}
                >
                  <div className="flex flex-row w-full whitespace-nowrap items-center h-full px-3">
                    <img
                      src={activity.img}
                      alt="distrupt"
                      className="w-24 laptop:w-[100px]"
                    />
                    {isLoading ? (
                      <></>
                    ) : isOpen ? (
                      <div className="flex justify-end w-full px-6">
                        <DisruptTimeAndDateComponent />
                      </div>
                    ) : (
                      <p
                        className={`w-full flex justify-end mx-auto px-10 ${
                          i18n.language === "en"
                            ? "font-helveticaMedium text-lg"
                            : "font-arMyriad text-xl"
                        }`}
                      >
                        {t(`${activity.description}`)}
                      </p>
                    )}
                  </div>
                </CardComponent>
              </Link>
            </div>
          ))}
        </div>
      </div>{" "}
      {/* //mobile and tablet */}
      <div className="mx-auto items-center mb-8 h-[121px] justify-center desktop:hidden largeLaptop:hidden laptop:hidden">
        {ActivitiesElement.map((activity, index) => (
          <Link to={appRoutesObj.getDisruptPagePath()} key={index}>
            <CardComponent
              className={`px-4 py-2 h-[121px] bg-neutral/10 tablet:w-full mobile:w-full rounded-2xl mb-3 from-neutral-900/50 via-neutral-900/50 to-secondary/20 ${
                i18n.language === "en"
                  ? "bg-gradient-to-br"
                  : "bg-gradient-to-bl"
              }${
                isDarkMode
                  ? "border border-gray-600/50"
                  : "border border-neutral/50"
              }`}
            >
              <div className="flex flex-row whitespace-nowrap items-center h-full px-3 text-white">
                <div>
                  <h1
                    className={`font-normal ${
                      i18n.language === "ar"
                        ? "font-arMyriad text-base"
                        : "font-helveticaBold text-base"
                    }`}
                  >
                    {t(`${activity.title}`)}
                  </h1>
                  <img
                    src={activity.img}
                    alt="distrupt"
                    className="pb-1 w-16 ml-6 laptop:w-[70px] -mt-3"
                  />
                </div>

                {isLoading ? (
                  <></>
                ) : isOpen ? (
                  <div className="flex justify-end w-full px-6">
                    <DisruptTimeAndDateComponent />
                  </div>
                ) : (
                  <p
                    className={`pt-1 largeLaptop:w-[260px]laptop:text-[10px] laptop:w-[150px] laptop:pb-4 ${
                      i18n.language === "ar"
                        ? "font-arMyriad text-base"
                        : "font-helveticaMedium text-xs"
                    }`}
                  >
                    {t(`${activity.description}`)}
                  </p>
                )}
              </div>
            </CardComponent>
          </Link>
        ))}
      </div>
    </>
  );
}
