import TitleComponent from "./common/title-component";

interface shardSection {
  component: JSX.Element;
  title: string;
}

export default function SharedSectionComponent(prop: shardSection) {
  return (
    <div className="mobile:px-0 tablet:px-0">
      <TitleComponent title={prop.title} color="text-white" />
      {prop.component}
    </div>
  );
}
