/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/named */
import {
  FormikTouched,
  FormikValues,
  FormikErrors,
  useFormik,
  Formik,
} from "formik";
import {  useRef, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { BsCheck2Circle } from "react-icons/bs";
import {   FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { Button } from "src/components/common/ui/Button";
import GridView from "src/components/common/ui/grid-view";
import InputField from "src/components/common/ui/InputField";
import TextAreaField from "src/components/common/ui/textArea";
import NumberStepsComponent from "src/components/number-steps-component";
import { handleRequestError } from "src/core/helpers/error.helper";
import useModeStore from "src/core/helpers/useModeStore";
import { PermitType } from "src/core/types/user.type";
import { postAcceleratedFormService } from "src/services/accelerated-form-service";

import { acceleratedData } from "./accelerated-form-data";
import { acceleratedValidationSchema } from "./accelerated-form-validation";

export default function AcceleratedFormContainer() {

  const sliderRef = useRef<HTMLDivElement>(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mode } = useModeStore();
  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [successfully, setSuccessfully] = useState<boolean>(false);
  const [isvalid, setIsvalid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fieldsToCheck: string[] = ["name", "mobile", "email","last_name"];
  const fieldsToCheck2: string[] = [
    "product_name",
    "product_main_idea",
    "product_differentiation",
    "idea_inspiration",
    "target_audience",
    "key_features",
    "unique_design_concept",
    "additional_features",
  ];
  const fieldsToCheck3: string[] = [
    "development_stage",
    "core_technology",
    "enhancement_plan",
    "technical_challenges",
    "testing_results",
    "development_methodologies",
    "user_experience_update_plan",
    "new_technologies_adoption",
  ];
  const fieldsToCheck4: string[] = [
    "market_size",
    "market_entry_strategy",
    "pricing_strategy",
    "market_research",
    "marketing_channels",
    "competition_strategy",
    "revenue_forecast",
    "post_launch_market_strategy",
  ];
  const fieldsToCheck5: string[] = [
    "distribution_plan",
    "required_resources",
    "suppliers_partners",
    "product_quality_plan",
    "logistical_challenges",
    "essential_logistics",
    "geographical_expansion",
    "contingency_plans",
  ];
  const fieldsToCheck6: string[] = [
    "personal_experiences",
    "alignment_with_values",
    "life_impact",
    "personal_challenges",
    "experience_shaping_vision",
    "influences_on_creation",
    "lessons_learned",
    "contribution_to_life_goals",
  ];
  const fieldsToCheck7: string[] = [
    "future_vision",
    "market_trends_effect",
    "personal_professional_goals",
    "future_collaborations",
    "product_evolution_over_time",
    "staying_ahead_of_trends",
    "product_legacy",
    "motivation_to_improve",
  ];

  const content = [
    { label: "app.shared.personalInformation", color: "#737373" },
    { label: "container.AcceleratedFormContainer.step1", color: "#737373" },
    { label: "container.AcceleratedFormContainer.step2", color: "#737373" },
    { label: "container.AcceleratedFormContainer.step3", color: "#737373" },
    { label: "container.AcceleratedFormContainer.step4", color: "#737373" },
    { label: "container.AcceleratedFormContainer.step5", color: "#737373" },
    { label: "container.AcceleratedFormContainer.step6", color: "#737373" },
  ];
  const isLastSection = activeIndex === content.length - 1;
  const handleSubmit = async () => {
    setIsLoading(!isLoading);
    try {
      if (executeRecaptcha) {
        const token = await executeRecaptcha();
        await postAcceleratedFormService({ ...formik.values }, token);
        setSuccessfully(!successfully);
      }
    } catch (error) {
      handleRequestError(error);
    }
  };
  const handleBackClick = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + content.length) % content.length
    );
  };
  const scrollToTop = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  let fieldsToValidate: string[];
  const handleNext = (
    fieldsToCheck: string[],
    formik: {
      setTouched: (touched: FormikTouched<PermitType>) => void;
      values: FormikValues;
      errors: FormikErrors<PermitType>;
    },
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>,

    content: any[]
  ) => {
    const newTouched: FormikTouched<PermitType> = {};

    fieldsToCheck.forEach((field) => {
      if (!formik.values[field]) {
        newTouched[field] = true;
      }
    });
    const currentStep = activeIndex;
  

    if (currentStep === 0) {
      fieldsToValidate = fieldsToCheck;
    
    } else if (currentStep === 1) {
      fieldsToValidate = fieldsToCheck2;
    
    } else if (currentStep === 2) {
      fieldsToValidate = fieldsToCheck3;
    } else if (currentStep === 3) {
      fieldsToValidate = fieldsToCheck4;
    } else if (currentStep === 4) {
      fieldsToValidate = fieldsToCheck5;
    } else if (currentStep === 5) {
      fieldsToValidate = fieldsToCheck6;
    } else if (currentStep === 6) {
      fieldsToValidate = fieldsToCheck7;
    } else {
      return handleSubmit();
    }

    fieldsToValidate.forEach((field) => {
      newTouched[field] = true;
    });

    formik.setTouched(newTouched);

    const hasErrors = fieldsToValidate.some(
      (field) => !formik.values[field] || !!formik.errors[field]
    );




    if (!hasErrors) {
      setIsvalid(!isvalid);
      setActiveIndex((prevIndex) =>
        Math.min(prevIndex + 1, content.length - 1)
      );
      scrollToTop();
    }

  };
  const formik = useFormik({
    initialValues: acceleratedData,
    onSubmit: async () => {
      await handleSubmit();
    },
    validationSchema: acceleratedValidationSchema(),
  });
 


  const scrollToError = (
    fieldsToCheck: string[],
    formik: {
      setTouched: (touched: FormikTouched<any>) => void;
      values: FormikValues;
      errors: FormikErrors<any>;
    },
  ) => {
    for (const field of fieldsToCheck) {
      if (formik.errors[field]) {
        const selector = `[name=${field}]`;
        const errorElement = document.querySelector(selector) as HTMLElement;
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          break; // Stop after scrolling to the first error
        }
      }
    }
  };
  

  const handlClick = () => {
    if (activeIndex === 0) {
      handleNext(fieldsToCheck, formik, setActiveIndex, content);
      scrollToError(fieldsToCheck,formik);
      
    

    } else if (activeIndex === 1) {
      handleNext(fieldsToCheck2, formik, setActiveIndex, content);
      scrollToError(fieldsToCheck2,formik);
    } else if (activeIndex === 2) {
      handleNext(fieldsToCheck3, formik, setActiveIndex, content);
      scrollToError(fieldsToCheck3,formik);

    } else if (activeIndex === 3) {
      handleNext(fieldsToCheck4, formik, setActiveIndex, content);
      scrollToError(fieldsToCheck4,formik);

    } else if (activeIndex === 4) {
      handleNext(fieldsToCheck5, formik, setActiveIndex, content);
      scrollToError(fieldsToCheck5,formik);

    } else if (activeIndex === 5) {
      handleNext(fieldsToCheck6, formik, setActiveIndex, content);
      scrollToError(fieldsToCheck6,formik);

    } else if (activeIndex === 6) {
      handleNext(fieldsToCheck7, formik, setActiveIndex, content);
      scrollToError(fieldsToCheck7,formik);

   

    }
    

    if (isLastSection) {
      setTimeout(async () => {
        await formik.handleSubmit();
      }, 500); // Adjust delay as needed
    }
  };
  

  

 


  return (
    <div className="h-full overflow-y-auto largeLaptop:h-auto mobile:px-0 tablet:px-2">
      {successfully ? (
        <div className="flex items-center justify-center py-52 largeLaptop:py-48 laptop:py-32 tablet:py-56 mobile:py-60">
          <div className="flex flex-col justify-end items-center gap-6 text-center tablet:px-4 tablet:py-[18px] tablet:rounded-lg tablet:bg-neutral-500/10 mobile:px-4 mobile:py-[18px] mobile:rounded-lg mobile:bg-neutral-500/10">
            <div>
              <BsCheck2Circle size={61} color="#50C5B1" />
            </div>
            <p
              className={` ${i18n.language === "en"
                  ? "font-helveticaBold text-lg leading-6 px-12 mobile:px-10"
                  : "font-arMyriad text-2xl"
                } w-96 mobile:w-80 ${mode === "dark" ? "text-white" : "text-slate-900"
                }`}
            >
              {t(`${"container.AcceleratedFormContainer.successfully"}`)}
            </p>
          </div>
        </div>
      ) : (
        <>
          <div
            className={`text-white justify-center text-center w-full pb-10 mobile:pb-0 ${i18n.language == "en"
                ? "font-helveticaBold text-lg"
                : "font-arMyriad text-[28px] leading-6"
              }`}
          >
            {t("container.AcceleratedFormContainer.title")}
          </div>
          <div className={`mobile:flex mobile:${i18n.language === "en" ? "pl-5":"pr-5"} tablet:flex tablet:gap-1`}>
          
            <div
              ref={sliderRef}
              className={`flex mobile:mt-2.5 tablet:mt-2.5 justify-between mobile:overflow-auto tablet:overflow-auto no-scrollbar px-0 mobile:gap-x-14
                } tablet:gap-4 text-neutral-500`}
            >
              {content.map((item, index) => (
                <NumberStepsComponent
                  className={`${i18n.language === 'en' ? "text-sm" : "text-lg"}`}
                  activeIndex={activeIndex}
                  index={index}
                  completed={activeIndex > index}
                  completedClass={"text-primary"}
                  color={"gray-500"}
                  label={item.label}
                  key={index}
                  size={"w-3 h-3"}
                  onClick={handlClick}
                />
              ))}
            </div>
            {i18n.language ==='en' ? <FiChevronsRight className="w-12 h-12 text-gray-400 laptop:hidden desktop:hidden largeLaptop:hidden" />:<FiChevronsLeft className="w-12 h-12 text-gray-400 laptop:hidden desktop:hidden largeLaptop:hidden" />}
          </div>

          <div  className="">
            <div ref={sliderRef} className="py-[18px] px-[161.5px] mobile:px-4 tablet:px-8 rounded-lg bg-neutral-500/10 h-[350px] largeLaptop:h-[240px] laptop:h-[170px] mobile:h-[400px] tablet:h-[360px] overflow-y-auto mobile:mb-2">
              <Formik
                initialValues={acceleratedData}
                onSubmit={() => formik.submitForm()}
                validationSchema={formik.initialValues}
                validateOnChange
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  {activeIndex === 0 && (
                    <GridView column={"1"}>
                      <div className="flex justify-between gap-4 mobile:flex-col tablet:flex-col mobile:gap-2 tablet:gap-2">
                        <InputField
                          label="app.shared.firstName"
                          name="name"
                          isValid={formik.touched.name && !formik.errors.name}
                          value={
                            formik.values.name != null ? formik.values.name : ""
                          }
                          errorMsg={
                            formik.touched.name && formik.errors.name
                              ? formik.errors.name
                              : ""
                          }
                          onChange={(e) => {
                            formik.setFieldValue("name", e.target.value);
                            formik.handleChange(e);
                          }}
                        />

                        <InputField
                          label="app.shared.lastName"
                          name="last_name"
                          isValid={
                            formik.touched.last_name && !formik.errors.last_name
                          }
                          value={
                            formik.values.last_name != null
                              ? formik.values.last_name
                              : ""
                          }
                          errorMsg={
                            formik.touched.last_name && formik.errors.last_name
                              ? formik.errors.last_name
                              : ""
                          }
                          onChange={(e) => {
                            formik.setFieldValue("last_name", e.target.value);
                            formik.handleChange(e);
                          }}
                        />
                      </div>
                      <div className="flex justify-between gap-4 mobile:flex-col tablet:flex-col mobile:gap-2 tablet:gap-2">
                        <InputField
                          label="app.shared.mobile.number"
                          name="mobile"
                          isValid={formik.touched.mobile && !formik.errors.mobile}
                          value={
                            formik.values.mobile != null ? formik.values.mobile : ""
                          }
                          errorMsg={
                            formik.touched.mobile && formik.errors.mobile
                              ? formik.errors.mobile
                              : ""
                          }
                          onChange={(e) => {
                            formik.setFieldValue("mobile", e.target.value);
                            formik.handleChange(e);
                          }}
                        />

                        <InputField
                          label="app.shared.email"
                          name="email"
                          isValid={
                            formik.touched.email && !formik.errors.email
                          }
                          value={
                            formik.values.email != null
                              ? formik.values.email
                              : ""
                          }
                          errorMsg={
                            formik.touched.email && formik.errors.email
                              ? formik.errors.email
                              : ""
                          }
                          onChange={(e) => {
                            formik.setFieldValue("email", e.target.value);
                            formik.handleChange(e);
                          }}
                        />
                      </div>
                    </GridView>
                  )}
                  {activeIndex === 1 && (
                    <GridView column={"1"}>
                      <TextAreaField

                        label="container.AcceleratedFormContainer.productName"
                        name="product_name"
                        isValid={
                          formik.touched.product_name &&
                          !formik.errors.product_name
                        }
                        value={
                          formik.values.product_name != null
                            ? formik.values.product_name
                            : ""
                        }
                        errorMsg={
                          formik.touched.product_name &&
                            formik.errors.product_name
                            ? formik.errors.product_name
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />

                      <TextAreaField
                        label="container.AcceleratedFormContainer.productMainIdea"
                        name="product_main_idea"
                        isLongTitle={true}
                        isValid={
                          formik.touched.product_main_idea &&
                          !formik.errors.product_main_idea
                        }
                        value={
                          formik.values.product_main_idea != null
                            ? formik.values.product_main_idea
                            : ""
                        }
                        errorMsg={
                          formik.touched.product_main_idea &&
                            formik.errors.product_main_idea
                            ? formik.errors.product_main_idea
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.productDifferentiation"
                        name="product_differentiation"
                        isLongTitle={true}
                        isValid={
                          formik.touched.product_differentiation &&
                          !formik.errors.product_differentiation
                        }
                        value={
                          formik.values.product_differentiation != null
                            ? formik.values.product_differentiation
                            : ""
                        }
                        errorMsg={
                          formik.touched.product_differentiation &&
                            formik.errors.product_differentiation
                            ? formik.errors.product_differentiation
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />

                      <TextAreaField
                        label="container.AcceleratedFormContainer.ideaInspiration"
                        name="idea_inspiration"
                        isValid={
                          formik.touched.idea_inspiration &&
                          !formik.errors.idea_inspiration
                        }
                        value={
                          formik.values.idea_inspiration != null
                            ? formik.values.idea_inspiration
                            : ""
                        }
                        errorMsg={
                          formik.touched.idea_inspiration &&
                            formik.errors.idea_inspiration
                            ? formik.errors.idea_inspiration
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.targetAudience"
                        name="target_audience"
                        isLongTitle={true}
                        isValid={
                          formik.touched.target_audience &&
                          !formik.errors.target_audience
                        }
                        value={
                          formik.values.target_audience != null
                            ? formik.values.target_audience
                            : ""
                        }
                        errorMsg={
                          formik.touched.target_audience &&
                            formik.errors.target_audience
                            ? formik.errors.target_audience
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.keyFeatures"
                        name="key_features"

                        isValid={
                          formik.touched.key_features &&
                          !formik.errors.key_features
                        }
                        value={
                          formik.values.key_features != null
                            ? formik.values.key_features
                            : ""
                        }
                        errorMsg={
                          formik.touched.key_features &&
                            formik.errors.key_features
                            ? formik.errors.key_features
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.uniqueDesignConcept"
                        name="unique_design_concept"
                        isValid={
                          formik.touched.unique_design_concept &&
                          !formik.errors.unique_design_concept
                        }
                        value={
                          formik.values.unique_design_concept != null
                            ? formik.values.unique_design_concept
                            : ""
                        }
                        errorMsg={
                          formik.touched.unique_design_concept &&
                            formik.errors.unique_design_concept
                            ? formik.errors.unique_design_concept
                            : ""
                        }
                        limit={300}
                        isLongTitle={true}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.additionalFeatures"
                        name="additional_features"
                        isLongTitle={true}
                        isValid={
                          formik.touched.additional_features &&
                          !formik.errors.additional_features
                        }
                        value={
                          formik.values.additional_features != null
                            ? formik.values.additional_features
                            : ""
                        }
                        errorMsg={
                          formik.touched.additional_features &&
                            formik.errors.additional_features
                            ? formik.errors.additional_features
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                    </GridView>
                  )}

                  {activeIndex === 2 && (
                    <GridView column={"1"}>
                      <TextAreaField
                        label="container.AcceleratedFormContainer.developmentStage"
                        name="development_stage"
                        isLongTitle={true}
                        isValid={
                          formik.touched.development_stage &&
                          !formik.errors.development_stage
                        }
                        value={
                          formik.values.development_stage != null
                            ? formik.values.development_stage
                            : ""
                        }
                        errorMsg={
                          formik.touched.development_stage &&
                            formik.errors.development_stage
                            ? formik.errors.development_stage
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />

                      <TextAreaField
                        label="container.AcceleratedFormContainer.coreTechnology"
                        name="core_technology"
                        isLongTitle={true}
                        isValid={
                          formik.touched.core_technology &&
                          !formik.errors.core_technology
                        }
                        value={
                          formik.values.core_technology != null
                            ? formik.values.core_technology
                            : ""
                        }
                        errorMsg={
                          formik.touched.core_technology &&
                            formik.errors.core_technology
                            ? formik.errors.core_technology
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.enhancementPlan"
                        name="enhancement_plan"
                        isLongTitle={true}
                        isValid={
                          formik.touched.enhancement_plan &&
                          !formik.errors.enhancement_plan
                        }
                        value={
                          formik.values.enhancement_plan != null
                            ? formik.values.enhancement_plan
                            : ""
                        }
                        errorMsg={
                          formik.touched.enhancement_plan &&
                            formik.errors.enhancement_plan
                            ? formik.errors.enhancement_plan
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />

                      <TextAreaField
                        label="container.AcceleratedFormContainer.technicalChallenges"
                        name="technical_challenges"
                        isLongTitle={true}
                        isValid={
                          formik.touched.technical_challenges &&
                          !formik.errors.technical_challenges
                        }
                        value={
                          formik.values.technical_challenges != null
                            ? formik.values.technical_challenges
                            : ""
                        }
                        errorMsg={
                          formik.touched.technical_challenges &&
                            formik.errors.technical_challenges
                            ? formik.errors.technical_challenges
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.testingResults"
                        name="testing_results"
                        isLongTitle={true}
                        isValid={
                          formik.touched.testing_results &&
                          !formik.errors.testing_results
                        }
                        value={
                          formik.values.testing_results != null
                            ? formik.values.testing_results
                            : ""
                        }
                        errorMsg={
                          formik.touched.testing_results &&
                            formik.errors.testing_results
                            ? formik.errors.testing_results
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.developmentMethodologies"
                        name="development_methodologies"
                        isLongTitle={true}
                        isValid={
                          formik.touched.development_methodologies &&
                          !formik.errors.development_methodologies
                        }
                        value={
                          formik.values.development_methodologies != null
                            ? formik.values.development_methodologies
                            : ""
                        }
                        errorMsg={
                          formik.touched.development_methodologies &&
                            formik.errors.development_methodologies
                            ? formik.errors.development_methodologies
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.userExperienceUpdatePlan"
                        name="user_experience_update_plan"
                        isLongTitle={true}
                        isValid={
                          formik.touched.user_experience_update_plan &&
                          !formik.errors.user_experience_update_plan
                        }
                        value={
                          formik.values.user_experience_update_plan != null
                            ? formik.values.user_experience_update_plan
                            : ""
                        }
                        errorMsg={
                          formik.touched.user_experience_update_plan &&
                            formik.errors.user_experience_update_plan
                            ? formik.errors.user_experience_update_plan
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.newTechnologiesAdoption"
                        name="new_technologies_adoption"
                        isLongTitle={true}
                        isValid={
                          formik.touched.new_technologies_adoption &&
                          !formik.errors.new_technologies_adoption
                        }
                        value={
                          formik.values.new_technologies_adoption != null
                            ? formik.values.new_technologies_adoption
                            : ""
                        }
                        errorMsg={
                          formik.touched.new_technologies_adoption &&
                            formik.errors.new_technologies_adoption
                            ? formik.errors.new_technologies_adoption
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                    </GridView>
                  )}

                  {activeIndex === 3 && (
                    <GridView column={"1"}>
                      <TextAreaField
                        label="container.AcceleratedFormContainer.marketSize"
                        name="market_size"
                        isLongTitle={true}
                        isValid={
                          formik.touched.market_size &&
                          !formik.errors.market_size
                        }
                        value={
                          formik.values.market_size != null
                            ? formik.values.market_size
                            : ""
                        }
                        errorMsg={
                          formik.touched.market_size &&
                            formik.errors.market_size
                            ? formik.errors.market_size
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />

                      <TextAreaField
                        label="container.AcceleratedFormContainer.marketEntryStrategy"
                        name="market_entry_strategy"
                        isLongTitle={true}
                        isValid={
                          formik.touched.market_entry_strategy &&
                          !formik.errors.market_entry_strategy
                        }
                        value={
                          formik.values.market_entry_strategy != null
                            ? formik.values.market_entry_strategy
                            : ""
                        }
                        errorMsg={
                          formik.touched.market_entry_strategy &&
                            formik.errors.market_entry_strategy
                            ? formik.errors.market_entry_strategy
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.pricingStrategy"
                        name="pricing_strategy"
                        isValid={
                          formik.touched.pricing_strategy &&
                          !formik.errors.pricing_strategy
                        }
                        value={
                          formik.values.pricing_strategy != null
                            ? formik.values.pricing_strategy
                            : ""
                        }
                        errorMsg={
                          formik.touched.pricing_strategy &&
                            formik.errors.pricing_strategy
                            ? formik.errors.pricing_strategy
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />

                      <TextAreaField
                        label="container.AcceleratedFormContainer.marketResearch"
                        name="market_research"
                        isLongTitle={true}
                        isValid={
                          formik.touched.market_research &&
                          !formik.errors.market_research
                        }
                        value={
                          formik.values.market_research != null
                            ? formik.values.market_research
                            : ""
                        }
                        errorMsg={
                          formik.touched.market_research &&
                            formik.errors.market_research
                            ? formik.errors.market_research
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.marketingChannels"
                        name="marketing_channels"
                        isLongTitle={true}
                        isValid={
                          formik.touched.marketing_channels &&
                          !formik.errors.marketing_channels
                        }
                        value={
                          formik.values.marketing_channels != null
                            ? formik.values.marketing_channels
                            : ""
                        }
                        errorMsg={
                          formik.touched.marketing_channels &&
                            formik.errors.marketing_channels
                            ? formik.errors.marketing_channels
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.competitionStrategy"
                        name="competition_strategy"
                        isLongTitle={true}
                        isValid={
                          formik.touched.competition_strategy &&
                          !formik.errors.competition_strategy
                        }
                        value={
                          formik.values.competition_strategy != null
                            ? formik.values.competition_strategy
                            : ""
                        }
                        errorMsg={
                          formik.touched.competition_strategy &&
                            formik.errors.competition_strategy
                            ? formik.errors.competition_strategy
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.revenueForecast"
                        name="revenue_forecast"
                        isLongTitle={true}
                        isValid={
                          formik.touched.revenue_forecast &&
                          !formik.errors.revenue_forecast
                        }
                        value={
                          formik.values.revenue_forecast != null
                            ? formik.values.revenue_forecast
                            : ""
                        }
                        errorMsg={
                          formik.touched.revenue_forecast &&
                            formik.errors.revenue_forecast
                            ? formik.errors.revenue_forecast
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.postLaunchMarketStrategy"
                        name="post_launch_market_strategy"
                        isLongTitle={true}
                        isValid={
                          formik.touched.post_launch_market_strategy &&
                          !formik.errors.post_launch_market_strategy
                        }
                        value={
                          formik.values.post_launch_market_strategy != null
                            ? formik.values.post_launch_market_strategy
                            : ""
                        }
                        errorMsg={
                          formik.touched.post_launch_market_strategy &&
                            formik.errors.post_launch_market_strategy
                            ? formik.errors.post_launch_market_strategy
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                    </GridView>
                  )}

                  {activeIndex === 4 && (
                    <GridView column={"1"}>
                      <TextAreaField
                        label="container.AcceleratedFormContainer.distributionPlan"
                        name="distribution_plan"
                        isLongTitle={true}
                        isValid={
                          formik.touched.distribution_plan &&
                          !formik.errors.distribution_plan
                        }
                        value={
                          formik.values.distribution_plan != null
                            ? formik.values.distribution_plan
                            : ""
                        }
                        errorMsg={
                          formik.touched.distribution_plan &&
                            formik.errors.distribution_plan
                            ? formik.errors.distribution_plan
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />

                      <TextAreaField
                        label="container.AcceleratedFormContainer.requiredResources"
                        name="required_resources"
                        isLongTitle={true}
                        isValid={
                          formik.touched.required_resources &&
                          !formik.errors.required_resources
                        }
                        value={
                          formik.values.required_resources != null
                            ? formik.values.required_resources
                            : ""
                        }
                        errorMsg={
                          formik.touched.required_resources &&
                            formik.errors.required_resources
                            ? formik.errors.required_resources
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.suppliersPartners"
                        name="suppliers_partners"
                        isLongTitle={true}
                        isValid={
                          formik.touched.suppliers_partners &&
                          !formik.errors.suppliers_partners
                        }
                        value={
                          formik.values.suppliers_partners != null
                            ? formik.values.suppliers_partners
                            : ""
                        }
                        errorMsg={
                          formik.touched.suppliers_partners &&
                            formik.errors.suppliers_partners
                            ? formik.errors.suppliers_partners
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.productQualityPlan"
                        name="product_quality_plan"
                        isLongTitle={true}
                        isValid={
                          formik.touched.product_quality_plan &&
                          !formik.errors.product_quality_plan
                        }
                        value={
                          formik.values.product_quality_plan != null
                            ? formik.values.product_quality_plan
                            : ""
                        }
                        errorMsg={
                          formik.touched.product_quality_plan &&
                            formik.errors.product_quality_plan
                            ? formik.errors.product_quality_plan
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.logisticalChallenges"
                        name="logistical_challenges"
                        isLongTitle={true}
                        isValid={
                          formik.touched.logistical_challenges &&
                          !formik.errors.logistical_challenges
                        }
                        value={
                          formik.values.logistical_challenges != null
                            ? formik.values.logistical_challenges
                            : ""
                        }
                        errorMsg={
                          formik.touched.logistical_challenges &&
                            formik.errors.logistical_challenges
                            ? formik.errors.logistical_challenges
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.essentialLogistics"
                        name="essential_logistics"
                        isLongTitle={true}
                        isValid={
                          formik.touched.essential_logistics &&
                          !formik.errors.essential_logistics
                        }
                        value={
                          formik.values.essential_logistics != null
                            ? formik.values.essential_logistics
                            : ""
                        }
                        errorMsg={
                          formik.touched.essential_logistics &&
                            formik.errors.essential_logistics
                            ? formik.errors.essential_logistics
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />

                      <TextAreaField
                        label="container.AcceleratedFormContainer.geographicalExpansion"
                        name="geographical_expansion"
                        isLongTitle={true}
                        isValid={
                          formik.touched.geographical_expansion &&
                          !formik.errors.geographical_expansion
                        }
                        value={
                          formik.values.geographical_expansion != null
                            ? formik.values.geographical_expansion
                            : ""
                        }
                        errorMsg={
                          formik.touched.geographical_expansion &&
                            formik.errors.geographical_expansion
                            ? formik.errors.geographical_expansion
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.contingencyPlans"
                        name="contingency_plans"
                        isLongTitle={true}
                        isValid={
                          formik.touched.contingency_plans &&
                          !formik.errors.contingency_plans
                        }
                        value={
                          formik.values.contingency_plans != null
                            ? formik.values.contingency_plans
                            : ""
                        }
                        errorMsg={
                          formik.touched.contingency_plans &&
                            formik.errors.contingency_plans
                            ? formik.errors.contingency_plans
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                    </GridView>
                  )}

                  {activeIndex === 5 && (
                    <GridView column={"1"}>
                      <TextAreaField
                        label="container.AcceleratedFormContainer.personalExperiences"
                        name="personal_experiences"
                        isLongTitle={true}
                        isValid={
                          formik.touched.personal_experiences &&
                          !formik.errors.personal_experiences
                        }
                        value={
                          formik.values.personal_experiences != null
                            ? formik.values.personal_experiences
                            : ""
                        }
                        errorMsg={
                          formik.touched.personal_experiences &&
                            formik.errors.personal_experiences
                            ? formik.errors.personal_experiences
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />

                      <TextAreaField
                        label="container.AcceleratedFormContainer.alignmentWithValues"
                        name="alignment_with_values"
                        isLongTitle={true}
                        isValid={
                          formik.touched.alignment_with_values &&
                          !formik.errors.alignment_with_values
                        }
                        value={
                          formik.values.alignment_with_values != null
                            ? formik.values.alignment_with_values
                            : ""
                        }
                        errorMsg={
                          formik.touched.alignment_with_values &&
                            formik.errors.alignment_with_values
                            ? formik.errors.alignment_with_values
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.lifeImpact"
                        name="life_impact"
                        isLongTitle={true}
                        isValid={
                          formik.touched.life_impact &&
                          !formik.errors.life_impact
                        }
                        value={
                          formik.values.life_impact != null
                            ? formik.values.life_impact
                            : ""
                        }
                        errorMsg={
                          formik.touched.life_impact &&
                            formik.errors.life_impact
                            ? formik.errors.life_impact
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.personalChallenges"
                        name="personal_challenges"
                        isLongTitle={true}
                        isValid={
                          formik.touched.personal_challenges &&
                          !formik.errors.personal_challenges
                        }
                        value={
                          formik.values.personal_challenges != null
                            ? formik.values.personal_challenges
                            : ""
                        }
                        errorMsg={
                          formik.touched.personal_challenges &&
                            formik.errors.personal_challenges
                            ? formik.errors.personal_challenges
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.experienceShapingVision"
                        name="experience_shaping_vision"
                        isLongTitle={true}
                        isValid={
                          formik.touched.experience_shaping_vision &&
                          !formik.errors.experience_shaping_vision
                        }
                        value={
                          formik.values.experience_shaping_vision != null
                            ? formik.values.experience_shaping_vision
                            : ""
                        }
                        errorMsg={
                          formik.touched.experience_shaping_vision &&
                            formik.errors.experience_shaping_vision
                            ? formik.errors.experience_shaping_vision
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.influencesOnCreation"
                        name="influences_on_creation"
                        isLongTitle={true}
                        isValid={
                          formik.touched.influences_on_creation &&
                          !formik.errors.influences_on_creation
                        }
                        value={
                          formik.values.influences_on_creation != null
                            ? formik.values.influences_on_creation
                            : ""
                        }
                        errorMsg={
                          formik.touched.influences_on_creation &&
                            formik.errors.influences_on_creation
                            ? formik.errors.influences_on_creation
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />

                      <TextAreaField
                        label="container.AcceleratedFormContainer.lessonsLearned"
                        name="lessons_learned"
                        isLongTitle={true}
                        isValid={
                          formik.touched.lessons_learned &&
                          !formik.errors.lessons_learned
                        }
                        value={
                          formik.values.lessons_learned != null
                            ? formik.values.lessons_learned
                            : ""
                        }
                        errorMsg={
                          formik.touched.lessons_learned &&
                            formik.errors.lessons_learned
                            ? formik.errors.lessons_learned
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.contributionToLifeGoals"
                        name="contribution_to_life_goals"
                        isLongTitle={true}
                        isValid={
                          formik.touched.contribution_to_life_goals &&
                          !formik.errors.contribution_to_life_goals
                        }
                        value={
                          formik.values.contribution_to_life_goals != null
                            ? formik.values.contribution_to_life_goals
                            : ""
                        }
                        errorMsg={
                          formik.touched.contribution_to_life_goals &&
                            formik.errors.contribution_to_life_goals
                            ? formik.errors.contribution_to_life_goals
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                    </GridView>
                  )}

                  {activeIndex === 6 && (
                    <GridView column={"1"}>
                      <TextAreaField
                        label="container.AcceleratedFormContainer.futureVision"
                        name="future_vision"
                        isLongTitle={true}
                        isValid={
                          formik.touched.future_vision &&
                          !formik.errors.future_vision
                        }
                        value={
                          formik.values.future_vision != null
                            ? formik.values.future_vision
                            : ""
                        }
                        errorMsg={
                          formik.touched.future_vision &&
                            formik.errors.future_vision
                            ? formik.errors.future_vision
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />

                      <TextAreaField
                        label="container.AcceleratedFormContainer.marketTrendsEffect"
                        name="market_trends_effect"
                        isLongTitle={true}
                        isValid={
                          formik.touched.market_trends_effect &&
                          !formik.errors.market_trends_effect
                        }
                        value={
                          formik.values.market_trends_effect != null
                            ? formik.values.market_trends_effect
                            : ""
                        }
                        errorMsg={
                          formik.touched.market_trends_effect &&
                            formik.errors.market_trends_effect
                            ? formik.errors.market_trends_effect
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.personalProfessionalGoals"
                        name="personal_professional_goals"
                        isLongTitle={true}
                        isValid={
                          formik.touched.personal_professional_goals &&
                          !formik.errors.personal_professional_goals
                        }
                        value={
                          formik.values.personal_professional_goals != null
                            ? formik.values.personal_professional_goals
                            : ""
                        }
                        errorMsg={
                          formik.touched.personal_professional_goals &&
                            formik.errors.personal_professional_goals
                            ? formik.errors.personal_professional_goals
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.futureCollaborations"
                        name="future_collaborations"
                        isLongTitle={true}
                        isValid={
                          formik.touched.future_collaborations &&
                          !formik.errors.future_collaborations
                        }
                        value={
                          formik.values.future_collaborations != null
                            ? formik.values.future_collaborations
                            : ""
                        }
                        errorMsg={
                          formik.touched.future_collaborations &&
                            formik.errors.future_collaborations
                            ? formik.errors.future_collaborations
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.productEvolutionOverTime"
                        name="product_evolution_over_time"
                        isLongTitle={true}
                        isValid={
                          formik.touched.product_evolution_over_time &&
                          !formik.errors.product_evolution_over_time
                        }
                        value={
                          formik.values.product_evolution_over_time != null
                            ? formik.values.product_evolution_over_time
                            : ""
                        }
                        errorMsg={
                          formik.touched.product_evolution_over_time &&
                            formik.errors.product_evolution_over_time
                            ? formik.errors.product_evolution_over_time
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.stayingAheadOfTrends"
                        name="staying_ahead_of_trends"
                        isLongTitle={true}
                        isValid={
                          formik.touched.staying_ahead_of_trends &&
                          !formik.errors.staying_ahead_of_trends
                        }
                        value={
                          formik.values.staying_ahead_of_trends != null
                            ? formik.values.staying_ahead_of_trends
                            : ""
                        }
                        errorMsg={
                          formik.touched.staying_ahead_of_trends &&
                            formik.errors.staying_ahead_of_trends
                            ? formik.errors.staying_ahead_of_trends
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />

                      <TextAreaField
                        label="container.AcceleratedFormContainer.productLegacy"
                        name="product_legacy"
                        isLongTitle={true}
                        isValid={
                          formik.touched.product_legacy &&
                          !formik.errors.product_legacy
                        }
                        value={
                          formik.values.product_legacy != null
                            ? formik.values.product_legacy
                            : ""
                        }
                        errorMsg={
                          formik.touched.product_legacy &&
                            formik.errors.product_legacy
                            ? formik.errors.product_legacy
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                      <TextAreaField
                        label="container.AcceleratedFormContainer.motivationToImprove"
                        name="motivation_to_improve"
                        isLongTitle={true}
                        isValid={
                          formik.touched.motivation_to_improve &&
                          !formik.errors.motivation_to_improve
                        }
                        value={
                          formik.values.motivation_to_improve != null
                            ? formik.values.motivation_to_improve
                            : ""
                        }
                        errorMsg={
                          formik.touched.motivation_to_improve &&
                            formik.errors.motivation_to_improve
                            ? formik.errors.motivation_to_improve
                            : ""
                        }
                        limit={300}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                    </GridView>
                  )}
                </form>
              </Formik>
            </div>
          </div>

          <div
            className={`flex items-center pt-4 w-[39.047%] mobile:w-full tablet:w-full gap-[7px] justify-center mx-auto mobile:px-0 tablet:px-0 mobile:pb-10`}
          >
            {activeIndex !== 0 && (
              <Button
                className="flex items-center justify-center w-1/2"
                disabled={isLoading}
                onClick={handleBackClick}
                type="submit"
              >
                {t("app.shared.back")}{" "}
              </Button>
            )}

            <Button
              className={`flex items-center justify-center ${activeIndex !== 0 ? "w-1/2" : "w-full"
                }`}
              disabled={isLoading}
              onClick={handlClick}
              type="submit"
            >
              {isLastSection ? t("app.shared.send") : t("app.shared.next")}{" "}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
