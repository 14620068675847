/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "src/components/common/Pagination/pagination";
import { NewsTypesEnum } from "src/core/enums/service.enums";
import { handleRequestError } from "src/core/helpers/error.helper";
import { GarageNewsList } from "src/core/types/user.type";
import { getNewsListService } from "src/services/news.service";

import VideoSkeletonContainer from "../skeleton/video-skeleton-container";

function VideoContainer() {
  const { i18n, t } = useTranslation();
  const [news, setNews] = useState<GarageNewsList>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const paginationLimit = 6;
  const [page, setPage] = useState<number>(1);

  const getAllGarageNews = useCallback(async () => {
    setIsLoading(true);
    try {
      const newsLater = await getNewsListService(
        i18n.language.toUpperCase(),
        NewsTypesEnum.NEWS_VIDEO,
        (page - 1) * paginationLimit,
        paginationLimit
      );
      setNews(newsLater);
      setIsLoading(false);
    } catch (error) {
      handleRequestError(error);
    }
  }, [i18n.language, page]);

  useEffect(() => {
    getAllGarageNews();
  }, [getAllGarageNews]);

  return (
    <div className="flex h-full flex-col pb-2">
      {isLoading ? (
        <VideoSkeletonContainer count={6} />
      ) : (
        <div className="grid grid-cols-3 grid-rows-2 gap-2 px-2 pt-4 mobile:grid-cols-1 tablet:h-screen tablet:grid-cols-1 tablet:px-40">
          {news?.result_list.map((item, index) => (
            <div
              key={index}
              className="h-56 rounded-2xl border border-gray-600/50 bg-neutral-500/10 p-3 backdrop-blur-lg mobile:bg-inherit mobile:backdrop-blur-[20px] largeLaptop:h-[180px] laptop:h-[155px]"
            >
              <div className="flex flex-col justify-center">
                <video
                  className="h-[170px] w-full rounded-xl object-cover largeLaptop:h-[130px] laptop:h-[100px]"
                  src={item.url}
                  controls
                />
                <p
                  className={`pt-3 text-white ${
                    i18n.language === "en"
                      ? "font-helveticaMedium text-sm"
                      : "font-arMyriad text-base"
                  }`}
                >
                  {t(`${item.title}`)}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="flex h-full items-end justify-start px-3 mobile:justify-center tablet:justify-center">
        <Pagination
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          totalPage={Math.ceil(news?.total_count! / paginationLimit)}
          page={page}
          limit={paginationLimit}
          onPageChange={setPage}
          totalCount={news?.total_count || 0}
        />
      </div>
    </div>
  );
}

export default VideoContainer;
