import React from "react";
import { useTranslation } from "react-i18next";

function DisruptTimerSkeletonComponent() {
  const { i18n, t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center w-full bg-black/20 h-[94px] backdrop-blur-lg rounded-2xl">
      <p
        className={`text-sm text-white ${
          i18n.language === "ar" ? "font-arMyriad" : "font-helveticaMedium"
        }`}
      >
        {t("container.disruptTimerContainer.launchingNextDisrupt")}
      </p>
      <div className="flex items-center justify-center gap-6 mobile:gap-3 pt-[6px]">
        <div className="flex flex-col items-center">
          <p
            className={`text-xs ${
              i18n.language === "ar" ? "font-arMyriad" : "font-helveticaMedium"
            } text-neutral-400`}
          >
            {t("component.disruptTimerContainer.DAYS")}
          </p>
          <div className="flex flex-row gap-1 pt-1.5">
            <div className="bg-primary-600 opacity-20 h-5 w-2.5 rounded-sm animate-pulse"></div>
            <div className="bg-primary-600 opacity-20 h-5 w-2.5 rounded-sm animate-pulse"></div>
          </div>
        </div>
        <p className="text-[32px] text-white font-helveticaBold">:</p>
        <div className="flex flex-col items-center">
          <span
            className={`text-xs ${
              i18n.language === "ar" ? "font-arMyriad" : "font-helveticaMedium"
            } text-neutral-400`}
          >
            {t("component.disruptTimerContainer.HOUR")}
          </span>
          <div className="flex flex-row gap-1 pt-1.5">
            <div className="bg-primary-600 opacity-20 h-5 w-2.5 rounded-sm animate-pulse"></div>
            <div className="bg-primary-600 opacity-20 h-5 w-2.5 rounded-sm animate-pulse"></div>
          </div>
        </div>
        <p className="text-[32px] text-white font-helveticaBold">:</p>
        <div className="flex flex-col items-center">
          <p
            className={`text-xs ${
              i18n.language === "ar" ? "font-arMyriad" : "font-helveticaMedium"
            } text-neutral-400`}
          >
            {t("component.disruptTimerContainer.MINUTE")}
          </p>
          <div className="flex flex-row gap-1 pt-1.5">
            <div className="bg-primary-600 opacity-20 h-5 w-2.5 rounded-sm animate-pulse"></div>
            <div className="bg-primary-600 opacity-20 h-5 w-2.5 rounded-sm animate-pulse"></div>
          </div>{" "}
        </div>
        <p className="text-[32px] text-white font-helveticaBold">:</p>
        <div className="flex flex-col items-center">
          <p
            className={`text-xs ${
              i18n.language === "ar" ? "font-arMyriad" : "font-helveticaMedium"
            } text-neutral-400`}
          >
            {t("component.disruptTimerContainer.SECOND")}
          </p>
          <div className="flex flex-row gap-1 pt-1.5">
            <div className="bg-primary-600 opacity-20 h-5 w-2.5 rounded-sm animate-pulse"></div>
            <div className="bg-primary-600 opacity-20 h-5 w-2.5 rounded-sm animate-pulse"></div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}

export default DisruptTimerSkeletonComponent;
