import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useModeStore from "src/core/helpers/useModeStore";

import Footer from "../Footer-component/footer-component";
import NavBar from "../Navbar-componet/nav-bar-componet";
import SidebarComponent from "../Sidebar-component/sidebar-component";
interface LayoutProps {
  children: ReactElement;
  sidebar?: boolean;
}
export function Layout({ children, sidebar = true }: LayoutProps) {
  const { i18n } = useTranslation();
  const { mode } = useModeStore();
  const isDarkMode = mode === "dark";
  const isSND = mode == "saudi-national-day";

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("language") || "ar");
  }, [i18n]);

  return (
    <div className="flex w-full h-full">
      <div
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
        className={`flex flex-col justify-center items-center h-[100dvh]  w-full  bg-cover bg-no-repeat ${
          isSND
            ? "saudi-national-day bg-SNDBackground largeLaptop:bg-center desktop:bg-center"
            : isDarkMode
            ? "bg-mainBackground"
            : "bg-mainBackgroundLight"
        }`}
      >
        {/* TODO: remove the mobile:backdrop-blur-sm tablet:backdrop-blur-sm when SND is done */}
        <div
          className={`flex justify-center mobile:h-full tablet:h-[100dvh] 
          rounded-[34px] mx-auto bg-neutral/10 backdrop-blur-lg mobile:backdrop-blur-sm tablet:backdrop-blur-sm ${
            sidebar ? "w-[82%]" : "w-[90%]"
          } laptop:w-[90%] mobile:rounded-none tablet:rounded-none 
          mobile:w-full tablet:w-full mobile:border-none tablet:border-none border desktop:border-gray-600/50 largeLaptop:border-gray-600/50 
          laptop:border-gray-600/50 px-4 mobile:px-0 tablet:px-0 laptop:py-2.5 py-5 mobile:py-0 tablet:py-0 laptop:my-6 largeLaptop:my-6 laptop:overflow-y-auto largeLaptop:overflow-y-auto`}
        >
          <div className="flex flex-col justify-between mx-auto mobile:hidden tablet:hidden">
            <div className="w-4 h-4 rounded-full bg-accent-default"></div>
            <div className="w-4 h-4 rounded-full bg-accent-default"></div>
          </div>
          <div className="flex flex-col w-full h-full ">
            <div className="tablet:px-3 mobile:px-3 py-1">
              <NavBar />
            </div>

            <div className="flex gap-x-6  mobile:flex-col justify-between tablet:flex-col mobile:overflow-y-scroll tablet:overflow-y-scroll">
              {sidebar && (
                <div className="mobile:hidden tablet:hidden">
                  <SidebarComponent />
                </div>
              )}
              {children}
            </div>
            <div className="mobile:hidden tablet:hidden">
              <Footer />
            </div>
          </div>
          <div className="flex flex-col justify-between mx-auto mobile:hidden tablet:hidden">
            <div className="w-4 h-4 rounded-full bg-accent-default"></div>
            <div className="w-4 h-4 rounded-full bg-accent-default"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
