import CardComponent from "src/components/user/card-component";
import i18n from "src/core/configs/i18n";

function ChangeLanguage() {
  const changeLanguage = () => {
    if (i18n.language === "ar") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else {
      i18n.changeLanguage("ar");
      localStorage.setItem("language", "ar");
    }
  };

  return (
    <div onClick={() => changeLanguage()}>
      <CardComponent className="flex flex-col items-center justify-center w-12 h-12 laptop:w-10 laptop:h-10 pt-1 text-base text-primary hover:text-primary-50 cursor-pointer">
        <p>{i18n.language === "ar" ? "EN" : "AR"}</p>
      </CardComponent>
    </div>
  );
}
export default ChangeLanguage;
