import { useTranslation } from "react-i18next";
import i18n from "src/core/configs/i18n";

function DisruptComingSoon() {
  const { t } = useTranslation();

  return (
    <div
      className={`flex h-[94px] flex-col items-center justify-center w-full bg-black/20 rounded-2xl bg-opacity-5`}
    >
      <img
        src="/assets/images/disrupt.svg"
        alt="distrupt"
        className="w-[150px] laptop:w-[79px] flex justify-center items-center"
      />
      <p
        className={`text-white ${
          i18n.language === "en"
            ? "font-helveticaMedium"
            : "font-arMyriad leading-5 text-xl"
        }`}
      >
        {t("container.disruptTimerContainer.CommingSoon")}
      </p>
    </div>
  );
}

export default DisruptComingSoon;
