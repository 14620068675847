import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import i18n from "src/core/configs/i18n";

import CarouselBtn from "./CarouselBtn";
import CarouselCard from "./CarouselCard";

interface Image {
  imgSrc: string;
  startup_name: string;
  description: string;
}

interface NewCarouselProps {
  Cards: Image[];
  autoPlay: boolean;
}
export const CarouselWidth = "326px";

function Carousel({ Cards, autoPlay }: NewCarouselProps): JSX.Element {
  const { t } = useTranslation();
  const [ImgIndex, setImgIndex] = useState<number>(0);

  const PrevCard = () => {
    setImgIndex(ImgIndex === 0 ? Cards.length - 1 : ImgIndex - 1);
  };
  const NextCard = () => {
    setImgIndex(ImgIndex === Cards.length - 1 ? 0 : ImgIndex + 1);
  };

  if (autoPlay) {
    useEffect(() => {
      const interval = setInterval(() => {
        NextCard();
      }, 1600);

      return () => clearInterval(interval); // Clean up the interval on unmount
    }, [ImgIndex]);
  }

  return (
    <div className="px-4">
      <div
        className={`order-1 grid grid-cols-2 justify-items-center lg:relative  lg:flex overflow-hidden ${
          i18n.language === "en" ? "lg:py-2" : ""
        }`}
      >
        <div
          className={`col-span-2 flex h-full w-full lg:order-2 lg:translate-x-0 `}
        >
          {Cards.map((val, index) => (
            <Link
              key={index}
              onClick={() => localStorage.setItem("PhotoId", String(index))}
              to={`${appRoutesObj.getStartUpPageUrl()}?photoId=${index}`}
              className={`w-full flex-shrink-0 flex-grow-0 p-5  pb-0 text-white transition-transform duration-1000 ease-in-out lg:w-full lg:p-2 w-[${CarouselWidth}]`}
              style={{
                transform: `translateX(${
                  i18n.language === "ar"
                    ? `${ImgIndex * 100}%`
                    : `-${ImgIndex * 100}%`
                })`,
              }}
            >
              <CarouselCard
                CompanyName={t(val.startup_name)}
                CompanyDescription={t(val.description)}
                imgSrc={val.imgSrc}
              />
            </Link>
          ))}
        </div>

        <div
          className={` ${
            i18n.language === "ar"
              ? "order-3 justify-self-start"
              : "order-2 justify-self-end"
          }`}
        >
          <CarouselBtn
            variant={"Left"}
            onClick={i18n.language === "ar" ? NextCard : PrevCard}
          />
        </div>
        <div
          className={`${
            i18n.language === "ar"
              ? "order-2 justify-self-end"
              : "order-3 justify-self-start"
          }`}
        >
          <CarouselBtn
            variant={"Right"}
            onClick={i18n.language === "ar" ? PrevCard : NextCard}
          />
        </div>
      </div>
    </div>
  );
}

export default Carousel;
