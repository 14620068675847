import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import PopupTapsComponent from "src/components/common/pop-up-taps-components";
import TitleComponent from "src/components/common/title-component";
import { Button } from "src/components/common/ui/Button";
import { ColorsEnum, VariantsEnum, SizesEnum } from "src/core/enums/ui.enums";
import { opengraphMetaTags } from "src/core/helpers/example.helper";
import { hostedPrograms } from "src/core/statics/hostedPrograms";

function HostedContainer() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [index, setIndex] = useState<number>(0);
  const [selectedProgram, setSelectedProgram] = useState<string>(
    hostedPrograms[0].name
  );
  const selectedProgramData = hostedPrograms.find(
    (h) => h.name === selectedProgram
  );

  return (
    <>
      <Helmet
        title={`${t("app.shared.theGarage")} | ${t(
          "containers.user.landing.theGarageHostedTitle"
        )}`}
        meta={[
          ...opengraphMetaTags({
            title: `${t("app.shared.theGarage")} | ${t(
              "containers.user.landing.theGarageHostedTitle"
            )}`,
            description: `${t("app.shared.hostedPrograms.description")}`,
            logo: "/assets/images/logo.png",
          }),
          {
            name: "description",
            content: `${t("app.shared.hostedPrograms.description")}`,
          },
        ]}
      />

      <PopupTapsComponent
        icon={
          <IoMdClose
            size={30}
            className="text-white"
            onClick={() => navigate(-1)}
          />
        }
        index={index}
        taps={
          <div className="flex gap-4 laptop:gap-0 mobile:gap-0">
            {hostedPrograms.map((items, index) => (
              <div
                key={index}
                onClick={() => {
                  setIndex(index);
                  setSelectedProgram(items.name);
                }}
                className={`p-4 ${
                  selectedProgram === items.name
                    ? "text-primary bg-gray-400/5 rounded-t-2xl backdrop-filter backdrop-blur-lg mobile:backdrop-filter-none mobile:bg-gradient-to-b mobile:from-[rgba(120,113,108,0.1)] mobile:to-[rgba(120,113,108,0.1)]"
                    : "text-white "
                }  text-center text-base items-center py-1 w-[162px] laptop:w-auto h-10 cursor-pointer mobile:w-auto mobile:text-[13px]`}
              >
                {t(`${items.name}`)}
              </div>
            ))}
          </div>
        }
      >
        <div>
          {selectedProgramData ? (
            <div className="px-3 mobile:px-0 mobile:h-[400px] tablet:h-[400px] h-auto">
              <div className="grid pb-0.5 mobile:gap-1">
                <TitleComponent
                  title="container.hostedContainer.title"
                  color="text-white"
                />
                <p
                  className={`text-neutral-400 pb-4 w-[70%] text-justify${
                    i18n.language === "ar"
                      ? "text-lg leading-[18px] font-arMyriad"
                      : "text-sm leading-[19.6px] font-helveticaMedium"
                  }`}
                >
                  {t(`${selectedProgramData.description}`)}
                </p>
              </div>
              <div className={"mobile:w-[35%] tablet:w-1/2"}>
                <Button
                  type="submit"
                  className={` ${
                    i18n.language === "en"
                      ? "text-sm"
                      : "text-base font-arMyriadBold"
                  }`}
                  color={ColorsEnum.Primary}
                  variant={VariantsEnum.Filled}
                  size={SizesEnum.Medium}
                  onClick={() => {
                    window.open(selectedProgramData.url, "_blank");
                  }}
                >
                  {t("app.shared.button")}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </PopupTapsComponent>
    </>
  );
}

export default HostedContainer;
