import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import PopupTapsComponent from "src/components/common/pop-up-taps-components";
import { opengraphMetaTags } from "src/core/helpers/example.helper";

import StartupDisruptContainer from "./startup-disrupt-container";
// import StartupIncubatorContainer from "./startup-incubator-container copy";
import StartupPlusContainer from "./startup-plus-container";
interface NewsPage {
  name: string;
  component: JSX.Element;
}

const srartUpPages: NewsPage[] = [
  {
    name: "Plus",
    component: <StartupPlusContainer />,
  },
  // {
  //   name: "Incubator",
  //   component: <StartupIncubatorContainer />,
  // },
  {
    name: "Disrupt",
    component: <StartupDisruptContainer />,
  },
];
function Startup() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState<JSX.Element>(
    srartUpPages[0].component
  );
  const handlePageChange = (component: JSX.Element) => {
    setSelectedPage(component);
  };
  const [index, setIndex] = useState<number>(0);

  return (
    <>
      <Helmet
        title={`${t("app.shared.theGarage")} | ${t(
          "container.user.community.communityTitle"
        )}`}
        meta={[
          ...opengraphMetaTags({
            title: `${t("app.shared.theGarage")} | ${t(
              "container.user.community.communityTitle"
            )}`,
            description: `${t(
              "component.user.SidebarComponent.theGarageDescreption"
            )}`,
            logo: "/assets/images/logo.png",
          }),
          {
            name: "description",
            content: `${t(
              "component.user.SidebarComponent.theGarageDescreption"
            )}`,
          },
        ]}
      />
      <PopupTapsComponent
        index={index}
        icon={
          <IoMdClose
            size={32}
            className="text-white"
            onClick={() => {
              navigate(-1);
            }}
          />
        }
        taps={
          <div className="flex gap-4 mobile:gap-0">
            {srartUpPages.map((items, index) => (
              <div
                key={index}
                onClick={() => {
                  handlePageChange(items.component);
                  setIndex(index);
                }}
                className={` ${
                  selectedPage === items.component
                    ? "text-primary bg-gray-400/5 rounded-t-2xl backdrop-filter backdrop-blur-lg  mobile:backdrop-filter-none mobile:bg-gradient-to-b mobile:from-[rgba(120,113,108,0.1)] mobile:to-[rgba(120,113,108,0.1)]"
                    : "text-white"
                } text-center text-base items-center py-1 px-4 w-[162px] h-10 cursor-pointer mobile:w-fit mobile:text-[13px]`}
              >
                {t(`${items.name}`)}
              </div>
            ))}
          </div>
        }
      >
        {selectedPage}
      </PopupTapsComponent>
    </>
  );
}

export default Startup;
