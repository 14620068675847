// import { PiMoonLight, PiSun } from "react-icons/pi";
import { PiSun, PiMoonLight } from "react-icons/pi";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import ChangeLanguage from "src/components/common/ChangeLanguageComponent/change-language";
import CardComponent from "src/components/user/card-component";
import useModeStore from "src/core/helpers/useModeStore";

function NavBar() {
  const { mode, setMode } = useModeStore();

  const handleChangeMode = () => {
    // TODO: uncomment this code on SND
    // localStorage.setItem("mode", "saudi-national-day");
    // setMode("saudi-national-day");

    if (localStorage.getItem("mode")) {
      if (localStorage.getItem("mode") === "dark") {
        localStorage.setItem("mode", "light");
        setMode("light");
      } else {
        localStorage.setItem("mode", "dark");
        setMode("dark");
      }
    } else {
      localStorage.setItem("mode", "dark");
      setMode("dark");
    }
  };

  const isDarkMode = mode === "dark";

  return (
    <div className="flex  flex-row justify-between mobile:flex-row mobile:items-center p-3 mobile:p-0 mobile:pt-5 h-fit">
      <Link to={appRoutesObj.getBasePath()}>
        <img
          className="max-h-12 cursor-pointer mobile:pt-2 laptop:max-h-10 mobile:max-h-[50px]"
          src="/assets/images/Layer.png"
          alt="logo"
        />
      </Link>
      <div className="flex flex-row gap-2">
        <ChangeLanguage />
        {/* TODO: MAKE THE BUTTON HIDDEN */}
        <button className="text-white" onClick={() => handleChangeMode()}>
          <CardComponent className="w-12 h-12 px-2.5 pt-2.5 laptop:pt-2 laptop:px-[7.5px] laptop:w-10 laptop:h-10 ">
            {isDarkMode ? <PiSun size={25} /> : <PiMoonLight size={25} />}
          </CardComponent>
        </button>
      </div>
    </div>
  );
}
export default NavBar;
