import React from "react";
import { useTranslation } from "react-i18next";
import { DirectionEnum } from "src/core/enums/ui.enums";

interface TextAreaFieldProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  limit?: number;
  helperText?: string;
  label?: string;
  error?: boolean;
  errorMsg?: string;
  isValid?: boolean;
  isLongTitle?: boolean;
}

const TextAreaField = ({
  label = "Label",
  limit,
 
  helperText = "",
  errorMsg = "",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isValid = true,
  ...props
}: TextAreaFieldProps): JSX.Element => {
  const { i18n,t } = useTranslation();
  const valueLength = props.value?.toString().length || 0;
const labelLength = (t(label)).length;
const paddingLabel = i18n.language === 'en'
  ? (labelLength > 48 
      ? "mobile:pt-12 tablet:pt-12" 
      : "mobile:pt-6 tablet:pt-[31px]")
  : (labelLength > 53 
      ? "mobile:pt-12 tablet:pt-10"  
      : "mobile:pt-6 tablet:pt-[28px]"); 

  const dir = i18n.language === "en" ? DirectionEnum.LTR : DirectionEnum.RTL;
  const getError = () => {
    if (errorMsg !== "") {
      isValid = false;
      return "border-red-500 focus:border-red-500 focus:border-2 ";
    }
    if (limit !== undefined && valueLength > limit) {
      isValid = false;
      return "border-red-500 focus:border-red-500 focus:border-2";
    }
    isValid = true;
    return "";
  };

  const getLabelVariant = () => {
    return `flex text-base peer-focus:text-base peer-focus:transform peer-focus:-translate-y-3 peer-focus:scale-95 text-opacity-80 bg-transparent px-1 mobile:px-0
    ${
      dir === DirectionEnum.LTR
        ? "left-0 ml-2 peer-focus:-translate-x-1"
        : "right-4 peer-focus:translate-x-2"
    }
    ${
      props.value && dir === DirectionEnum.LTR && isValid
        ? `transform -translate-y-3`
        : props.value && dir === DirectionEnum.RTL && isValid
        ? "transform -translate-y-3  translate-x-3 scale-95"
        : (!isValid && props.value) || !isValid
        ? `transform -translate-y-4 scale-95`
        : props.value
        ? "transform  translate-x-3"
        : ""
    }`;
  };

  const getInputVariant = () => {
    return `rounded-md placeholder:opacity-0 focus:border focus:border-neutral-200 focus:placeholder:opacity-100 duration-100 px-4 ${
      errorMsg !== "" ? `border ${getError()}` : ""
    }`;
  };

  return (
    <div
      className="flex flex-col relative w-full justify-center mx-auto"
      dir={dir}
    >
      <div
        className={`peer pt-8 pb-1 h-32 focus:outline-none text-base ${paddingLabel} bg-neutral-500/10 transition text-white resize-none disabled:text-gray-400 disabled:border disabled:border-none border-neutral-500/10  ${getInputVariant()}`}
      >
        <textarea
          {...props}
          value={props.value}
          className="w-full h-full block border-none bg-transparent outline-none resize-none"
        />
      </div>

      <label
        className={`absolute ${
          i18n.language === "en"
            ? "font-helveticaMedium text-base"
            : "font-arMyriad text-lg"
        } transition peer-focus:text-gray-400 duration-200 text-gray-400  ${
          limit ? "disabled:bg-transparent top-4" : "bottom-1/3"
        } ${getLabelVariant()} `}
      >
        {t(label)}
   
      </label>
      <div
        className={`flex justify-between px-2 py-1 text-zinc-600 ${
          i18n.language === "en"
            ? "font-helveticaMedium text-xs"
            : "font-arMyriad text-base"
        }`}
      >
        {(limit && limit < valueLength) || errorMsg !== "" ? (
          <p className={`${getError()} text-red-500`}>{t(errorMsg)}</p>
        ) : (
          <p className={`${getError()}`}>{t(helperText)}</p>
        )}
        {limit && (
          <span className={`${valueLength > limit ? "text-red-500" : ""}`}>
            {props.value?.toString().length}/{limit}
          </span>
        )}
      </div>
    </div>
  );
};

export default TextAreaField;