import { useTranslation } from "react-i18next";

import { ReportProblemButtonComponent } from "./report-problem-button-component";
import BackButton from "./ui/BackButton";

interface SharedButtonComponentProp{
    isLastSection:boolean,
    onClick: () => void;
    isLoading:boolean;
    handleBackClick: () => void;
    activeIndex:number
}

export function SharedButtonComponent(prop:SharedButtonComponentProp) {
  const { t } = useTranslation();

  return (
    <div
    className={`flex flex-row justify-center gap-4 mobile:gap-2 largeLaptop:pt-16 laptop:pt-7 mobile:pt-6 pt-10 `}
  >
        {prop.activeIndex !== 0 && <BackButton   isLoading={prop.isLoading} onClick={prop.handleBackClick} />}
    <ReportProblemButtonComponent
    isLoading={prop.isLoading}
      title={`${
        prop.isLastSection ? t("app.shared.send") : t("app.shared.next")
      }`}
      onClick={prop.onClick}
    />

  </div>
  );
}
