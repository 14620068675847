import i18n from 'src/core/configs/i18n';

function NewsSkeletonCard(): JSX.Element {
  return (
    <div
      className={
        "h-56 animate-pulse rounded-2xl border border-gray-600/50 bg-neutral-500/10 p-3 backdrop-blur-lg mobile:bg-inherit mobile:backdrop-blur-[20px] largeLaptop:h-[180px] laptop:h-[150px]"
      }
    >
      <div
        className={
          "h-[120px] w-full animate-pulse rounded-xl bg-neutral-800 object-cover largeLaptop:h-[90px] laptop:h-20"
        }
      ></div>
      <div
        className={`mt-3 animate-pulse rounded-full bg-gray-600 pt-3 ${
          i18n.language === "en"
            ? "font-helveticaMedium text-sm largeLaptop:text-xs"
            : "font-arMyriad text-base"
        }`}
      ></div>

      <div
        className={`absolute bottom-4 text-xs bg-gray-600 animate-pulse rounded-full h-3 w-1/2 ${
          i18n.language === "en"
            ? "right-4 text-right font-helveticaMedium"
            : "left-4 text-left font-arMyriad"
        }`}
      ></div>
    </div>
  );
}

export default NewsSkeletonCard;
